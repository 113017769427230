import React from "react";
import TimelineItem from "./TimelineItem";

const timelineData = [
  {
    date: "Monday",
    text: "Arrival of the TUM Talents on-site at the company, getting to know the company and the operational problem (already solved)",
    secondaryText: "",
  },
  {
    date: "Tuesday",
    text: "Working on the company’s operational problem guided by the company",
    secondaryText: "",
  },
  {
    date: "Wednesday",
    text: "TUM Talents reflecting the first solution approach with the company",
    secondaryText: "",
  },
  {
    date: "Thursday",
    text: "Finalizing and documenting the problem-solving approach by TUM Talents on poster for presentation",
    secondaryText: "",
  },
  {
    date: "Friday",
    text: "TUM Talents presenting their solution, followed by discussion and reflection with the company",
    secondaryText: "Departure of the TUM Talents",
  },
  {
    date: "April 2025",
    text: "Final 1.000+ project week event at TUM campus with all involved parties",
    secondaryText: "",
  },
];

function Timeline() {
  const headingStyle = {
    textAlign: "center",
    fontWeight: 600,
    padding: "40px",
    fontSize: "30px",
    color: "#313131",
  };

  return (
    <div
      id="timeline"
      style={{
        padding: "20px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fafafa",
      }}
    >
      <h1 style={headingStyle}>Timeline</h1>
      <div className="timeline-container">
        {timelineData.map((data, idx) => (
          <TimelineItem data={data} key={idx} />
        ))}
      </div>
    </div>
  );
}

export default Timeline;
