import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

const AntTable = ({ data, columns }) => {
  return (
    <div className="container-fluid">
      <div style={{ padding: "0 16px", overflowX: "auto" }}>
        <BootstrapTable
          keyField="matrikelnummer"
          data={data}
          columns={columns}
          responsive
          striped
          hover
          condensed
        />
      </div>
    </div>
  );
};

export default AntTable;

