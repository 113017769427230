import React, { useState, useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import "./AutoCarousel.css";

function AutoCarousel({ logos }) {
  const [perPage, setPerPage] = useState(calculatePerPage());

	// Function to calculate the number of slides per page based on screen size
	function calculatePerPage() {

    if (window.innerWidth < 640) { // Mobile devices
        return 4; // Smaller number of items for smaller screens
    } else if (window.innerWidth >= 640 && window.innerWidth < 768) { // Tablets in portrait
        return 6;
    } else if (window.innerWidth >= 768 && window.innerWidth < 1200) { // Tablets in landscape
        return 8;
    } else { // Larger desktops
        return 12;
    }
}

	// Event listener to update perPage state on window resize
	useEffect(() => {
		function handleResize() {
			setPerPage(calculatePerPage());
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []); // Empty dependency array to ensure effect runs only once


  return (
    <Splide
      options={{
        type: "loop",
        gap: "2px",
        drag: "free",
        arrows: false,
        pagination: false,
        perPage: perPage,
        autoScroll: {
          pauseOnHover: true,
          pauseOnFocus: false,
          rewind: false,
          speed: 1,
        },
      }}
      extensions={{ AutoScroll }}
    >
      {logos.map((logo) => (
        <SplideSlide key={logo.name}>
          <div className="logos-container">
            <a href={logo.url} target="_blank" rel="noopener noreferrer">
              <img src={logo.src} alt={logo.name} className="company-logo lazyload" />
            </a>
          </div>
        </SplideSlide>
      ))}
    </Splide>
  );
}

export default AutoCarousel;
