import axios from "axios";

import { GET_ERRORS, GET_INVITATION_KEY, SET_CURRENT_USER } from "./types";


//Set logged in user
export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
};

//Get invitation Key
export const getInvitationKey = () => (dispatch) => {
  axios
    .get("/api/users/createinvitationkey")
    .then((res) => {
      dispatch({
        type: GET_INVITATION_KEY,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Log user out
export const logoutUser = () => (dispatch) => {
  // remove auth token
  axios.get("/api/auth/logout");
  localStorage.clear();

  //Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
