// src/Poster.js
import React from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Poster = ({ imageThumbnailSrc, altText, onClick }) => {
  const posterThumbnailStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "360px", // Adjust width as needed
    border: "1px solid #ddd",
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "white",
    margin: "10px", // Added margin for spacing between posters
    cursor: "pointer",
    //border: "3px solid #64A0C8",
  };

  const posterImageStyle = {
    width: "100%",
    height: "auto",
    objectFit: "cover",
  };

  return (
    <span style={posterThumbnailStyle} onClick={onClick}>
      <img src={imageThumbnailSrc} alt={altText} style={posterImageStyle} />
    </span>
  );
};

Poster.propTypes = {
  imageThumbnailSrc: PropTypes.string.isRequired,
  altText: PropTypes.string,
  onClick: PropTypes.func,
};

Poster.defaultProps = {
  altText: "Poster image",
  onClick: () => {},
};

export default Poster;
