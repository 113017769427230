// src/PosterDetail.js
import React from "react";
import PropTypes from "prop-types";

const PosterDetail = ({
  imageSrc,
  title,
  description,
  companyDetails,
  onNavigate,
}) => {
  const posterDetailStyle = {
    textAlign: "center",
    padding: "20px",
  };

  const posterImageStyle = {
    width: "100%",
    maxWidth: "850px",
    height: "auto",
    marginBottom: "20px",
  };
  const buttonStyle = {
    backgroundColor: "#64A0C8",
    color: "white",
    borderRadius: "4px",
    marginRight: "20px",
  };
  const headerStyle = {
    textAlign: "left",
    marginLeft: "180px",
  };

  return (
    <div>
      <div style={posterDetailStyle}>
        <h2 className="my-3" style={headerStyle}>
          {title}
        </h2>
        <button
          style={buttonStyle}
          className="rounded-button primary"
          onClick={onNavigate}
        >
          Back to Posters
        </button>
        <img src={imageSrc} alt={title} style={posterImageStyle} />
      </div>
    </div>
  );
};

PosterDetail.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  companyDetails: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

export default PosterDetail;
