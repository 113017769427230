import React from "react";
import { Layout } from "antd";
const { Footer } = Layout;

const AntFooter = () => {
  return (
    <Footer style={{ backgroundColor: "black" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap", // Allow items to wrap to the next row on smaller screens
        }}
      >
        <div style={{
          display: "flex",
          flexWrap: "wrap", // Allow items to wrap to the next row on smaller screens
        }}>
          <a
            style={{
              padding: "5px 10px",
              color: "white",
              textDecoration: "none",
            }}
            href="https://www.cit.tum.de/cit/datenschutz/"
          >
            Datenschutz
          </a>
          <a
            style={{
              padding: "5px 10px",
              color: "white",
              textDecoration: "none",
            }}
            href="https://www.cit.tum.de/cit/impressum/"
          >
            Impressum
          </a>
          <a
            style={{
              padding: "5px 10px",
              color: "white",
              textDecoration: "none",
            }}
            href="https://www.cit.tum.de/cit/erklaerung-barrierefreiheit/"
          >
            Barrierefreiheit
          </a>
        </div>
        <div
          style={{
            padding: "5px 10px",
            color: "white",
            textDecoration: "none",
            textAlign: "center", // Align the text to the right
            whiteSpace: "wrap", // Prevent the text from wrapping
          }}
        >
          Contact us at <strong>1000plus@cit.tum.de</strong> for more questions.
        </div>
      </div>
    </Footer>
  );
};

export default AntFooter;
