import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Typography,
  Modal,
  Checkbox,
} from "antd";
import Button from "../../ui/button/Button";
import { fieldofstudy } from "../create-profile/studyCourses";
import CountryList from "../../countryselector/country-list";

const { TextArea } = Input;
const { Option } = Select;

export const RegisterCompany = (props) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [termsOfServiceAgreed, setTermsOfServiceAgreed] = useState(false);
  const [consentDeclarationAgreed, setConsentDeclarationAgreed] =
    useState(false);
  const [coverCostsAgreed, setCoverCostsAgreed] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCountries(CountryList.getData());
  }, []);

  const onFinish = async (values) => {
    const {
      termsOfServiceAgreed,
      coverCostsAgreed,
      consentDeclarationAgreed,
      ...filteredValues
    } = values;

    filteredValues.ndaRequired =
      filteredValues.ndaRequired === "yes" ? "yes" : "no";
    if (!filteredValues.address.website) {
      filteredValues.address.website = "";
    }
    try {
      await axios.post("/api/company", filteredValues);
      setShowSuccess(true);
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  const handleBack = () => {
    props.history.push("/");
  };

  return (
    <div className="register-company">
      <div className="col-md-8 m-auto">
        <h2
          style={{
            padding: "30px 12px",
            paddingBottom: "20px",
            fontWeight: 800,
          }}
        >
          REGISTER YOUR COMPANY
        </h2>

        <Form layout="vertical" onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col span={24} style={{ padding: "0px 20px" }}>
              <h4>General information</h4>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Name of Company"
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the name of your company",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Field of Business"
                name="fieldOfBusiness"
                rules={[
                  {
                    required: true,
                    message: "Please select the field of business",
                  },
                ]}
              >
                <Select>
                  <Option value="Mobility">Mobility</Option>
                  <Option value="IT">IT</Option>
                  <Option value="Security">Security</Option>
                  <Option value="Digitization">Digitization</Option>
                  <Option value="Material & Production">
                    Material & Production
                  </Option>
                  <Option value="Energy & Construction">
                    Energy & Construction
                  </Option>
                  <Option value="Health">Health</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Preferred Fields of Study (Max 5)"
                name="preferredFieldOfStudy"
                rules={[
                  {
                    required: true,
                    message: "Please enter the preferred field of study",
                  },
                  () => ({
                    validator(_, value) {
                      if (value && value.length > 5) {
                        return Promise.reject(
                          new Error(
                            "Please select only up to 5 fields of study."
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Select
                  mode="multiple"
                  maxTagCount={5}
                  placeholder="Preferred Field of Study"
                >
                  {fieldofstudy.map((field) => (
                    <Option key={field.value} value={field.value}>
                      {field.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Size of Company"
                name="companySize"
                rules={[
                  {
                    required: true,
                    message: "Please enter the size of your company",
                  },
                ]}
              >
                <Select>
                  <Option value="Small">Small (0-10 employees)</Option>
                  <Option value="Medium">Medium (10-50 employees)</Option>
                  <Option value="Large">Large (50+ employees)</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Description of Company"
                name="companyDescription"
                rules={[
                  {
                    required: true,
                    message: "Please enter the description of your company",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Potential real business problem (solved) for students"
                name="businessProblem"
                rules={[
                  {
                    required: true,
                    message: "Please enter the potential business problem",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Reason for Participation"
                name="reasonForParticipation"
                rules={[
                  {
                    required: true,
                    message: "Please enter the reason for participation",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="How did you find out about 1.000+"
                name="sourceOfInformation"
                rules={[
                  {
                    required: true,
                    message: "Please enter how you found out about 1.000+",
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Is a Non-Disclosure Agreement (NDA) required for Students?"
                name="ndaRequired"
                rules={[
                  {
                    required: true,
                    message: "Please select whether an NDA is required",
                  },
                ]}
              >
                <Select>
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ padding: "0px 20px" }}>
              <h4>Address</h4>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Street"
                name={["address", "street"]}
                rules={[{ required: true, message: "Please enter the street" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="House Number"
                name={["address", "houseNumber"]}
                rules={[
                  { required: true, message: "Please enter the house number" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="City"
                name={["address", "city"]}
                rules={[{ required: true, message: "Please enter the city" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Country"
                name={["address", "country"]}
                rules={[
                  { required: true, message: "Please select the country" },
                ]}
              >
                <Select>
                  {countries.map((country) => (
                    <Option key={country.value} value={country.value}>
                      {country.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item label="Website" name={["address", "website"]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={{ padding: "0px 20px" }}>
              <h4>Contact Person</h4>
              <p>
                * Responsible for the project and contact person for TUM Talents
              </p>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="First Name"
                name={["contactPerson", "firstName"]}
                rules={[
                  { required: true, message: "Please enter the first name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Last Name"
                name={["contactPerson", "lastName"]}
                rules={[
                  { required: true, message: "Please enter the last name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Email"
                name={["contactPerson", "email"]}
                rules={[
                  { required: true, message: "Please enter the email" },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
              <Form.Item
                label="Phone"
                name={["contactPerson", "phone"]}
                rules={[
                  { required: true, message: "Please enter the phone number" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col style={{ padding: "0px 20px" }}>
              <Form.Item
                name="termsOfServiceAgreed"
                rules={[
                  () => ({
                    message: "Please agree to the Terms of Service",
                    validator(rule, value) {
                      if (termsOfServiceAgreed === false) {
                        return Promise.reject(new Error());
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Checkbox
                  name="termsOfServiceAgreed"
                  checked={termsOfServiceAgreed}
                  onChange={(e) => setTermsOfServiceAgreed(e.target.checked)}
                >
                  I agree with the{" "}
                  <a
                    href="/consent/Terms_of_Service_Company.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  .
                </Checkbox>
              </Form.Item>

              <Form.Item
                name="consentDeclarationAgreed"
                rules={[
                  () => ({
                    message: "Please agree to the Declaration of Consent",
                    validator(rule, value) {
                      if (consentDeclarationAgreed === false) {
                        return Promise.reject(new Error());
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Checkbox
                  name="consentDeclarationAgreed"
                  checked={consentDeclarationAgreed}
                  onChange={(e) =>
                    setConsentDeclarationAgreed(e.target.checked)
                  }
                >
                  I agree with the{" "}
                  <a
                    href="/consent/Declaration_of_Consent.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Declaration of Consent
                  </a>
                  .
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="coverCostsAgreed"
                rules={[
                  () => ({
                    message:
                      "Please agree to covering the costs of the student team",
                    validator(rule, value) {
                      if (coverCostsAgreed === false) {
                        return Promise.reject(new Error());
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Checkbox
                  checked={coverCostsAgreed}
                  onChange={(e) => setCoverCostsAgreed(e.target.checked)}
                >
                  I agree to cover the costs for the student team of Project
                  Week 1.000+.
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Col xs={24} md={24} style={{ padding: "20px" }}>
            <Form.Item>
              <Button variant="primary" htmlType="submit" text="SUBMIT" />
            </Form.Item>
          </Col>
          <Modal
            title={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px 0px",
                }}
              >
                <h2 style={{ color: "#DB6C1F", fontWeight: "700" }}>
                  THANK YOU!
                </h2>
              </div>
            }
            open={showSuccess}
            closable={false}
            keyboard={false}
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <Button
                  text="GO BACK TO HOMEPAGE"
                  onClick={handleBack}
                  variant={"secondary"}
                  style={{ color: "white" }}
                />
              </div>,
            ]}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              You have successfully registered with us for the Project week
              1.000+ 2025
            </p>
          </Modal>
        </Form>
      </div>
    </div>
  );
};
