import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      src={`https://www.youtube-nocookie.com/embed/${embedId}?vq=hd1080&rel=0&color=white`}
      title="1.000+ Project Week 2024 @ TUM/CIT"
      frameBorder="0"
      allowFullScreen
      className="lazyload rounded"
    ></iframe>
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
