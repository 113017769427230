import React from 'react';

const Button = ({ text, variant, onClick=() => {}, style={}, type={} }) => {
  const buttonClass = variant === 'primary' ? 'primary' : 'secondary';

  return (
    <button className={`rounded-button ${buttonClass}`} onClick={onClick} style={{...style}} htmlType={type}>
      {text}
    </button>
  );
};

export default Button;