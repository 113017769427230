import React, { useEffect, useState } from "react";
import AntTable from "../../ui/table/Table";
import axios from "axios";
import { Button } from "antd";

function ViewCompanyProfiles() {
  const [companies, setCompanies] = useState([]);

  const ButtonStyle = {
    backgroundColor: "#64A0C8",
    color: "white",
    borderRadius: "4px",
  };

  const columns = [
    {
      dataField: "companyName",
      text: "Company Name",
    },
    {
      dataField: "companyDescription",
      text: "Company Description",
    },
    {
      dataField: "fieldOfBusiness",
      text: "Field of Business",
    },
    {
      dataField: "companySize",
      text: "Company Size",
    },
    {
      dataField: "businessProblem",
      text: "Business Problem",
    },
    // Split preferred field of study into 5 separate columns
    {
      dataField: "preferredFieldOfStudy[0]",
      text: "Preferred Field of Study 1",
      formatter: (cell) => cell || "", // Display the value or empty string if null
      headerStyle: { minWidth: "150px" },
      style: { minWidth: "150px" },
    },
    {
      dataField: "preferredFieldOfStudy[1]",
      text: "Preferred Field of Study 2",
      formatter: (cell) => cell || "",
      headerStyle: { minWidth: "150px" },
      style: { minWidth: "150px" },
    },
    {
      dataField: "preferredFieldOfStudy[2]",
      text: "Preferred Field of Study 3",
      formatter: (cell) => cell || "",
      headerStyle: { minWidth: "150px" },
      style: { minWidth: "150px" },
    },
    {
      dataField: "preferredFieldOfStudy[3]",
      text: "Preferred Field of Study 4",
      formatter: (cell) => cell || "",
      headerStyle: { minWidth: "150px" },
      style: { minWidth: "150px" },
    },
    {
      dataField: "preferredFieldOfStudy[4]",
      text: "Preferred Field of Study 5",
      formatter: (cell) => cell || "",
      headerStyle: { minWidth: "150px" },
      style: { minWidth: "150px" },
    },
    {
      dataField: "sourceOfInformation",
      text: "Source of Information",
    },
    {
      dataField: "reasonForParticipation",
      text: "Reason for Participation",
    },
    {
      dataField: "address.country",
      text: "Country",
    },
    {
      dataField: "address.city",
      text: "City",
    },
    {
      dataField: "address.street",
      text: "Street",
    },
    {
      dataField: "address.houseNumber",
      text: "House Number",
    },
    {
      dataField: "address.website",
      text: "Website",
      formatter: (cell) =>
        cell ? (
          <a href={cell} target="_blank" rel="noopener noreferrer">
            {cell}
          </a>
        ) : (
          ""
        ), // Link to the website
    },
    {
      dataField: "contactPerson.firstName",
      text: "Contact First Name",
    },
    {
      dataField: "contactPerson.lastName",
      text: "Contact Last Name",
    },
    {
      dataField: "contactPerson.email",
      text: "Contact Email",
    },
    {
      dataField: "contactPerson.phone",
      text: "Contact Phone",
    },
    {
      dataField: "semester",
      text: "Semester",
    },
    {
      dataField: "ndaRequired",
      text: "NDA Required",
    },
  ];

  const exportCompanyInformation = () => {
    axios({
      url: `/api/company/downloadcompanies`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Registered-Companies.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get("/api/company");
        // Ensure that each company's preferredFieldOfStudy is an array with 5 elements
        const transformedData = response.data.map((company) => ({
          ...company,
          preferredFieldOfStudy: [
            ...(company.preferredFieldOfStudy || []),
            "",
            "",
            "",
            "",
            "",
          ].slice(0, 5),
        }));
        setCompanies(transformedData);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  return (
    <div className="d-flex flex-column align-items-center p-5">
      <Button
        type="primary"
        style={ButtonStyle}
        onClick={exportCompanyInformation}
      >
        Export Companies Information in excel
      </Button>
      <AntTable data={companies} columns={columns} />
    </div>
  );
}

export default ViewCompanyProfiles;
