import React, { useEffect } from "react";
import AntTable from "../../ui/table/Table";
import { useSelector, useDispatch } from "react-redux";
import { getProfiles } from "../../../actions/profileActions";
import axios from "axios";
import { Button } from "antd";

function ViewStudentProfiles() {
  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.profile.profiles) || [];

  const ButtonStyle = {
    backgroundColor: "#64A0C8",
    color: "white",
    borderRadius: "4px",
  };

  const columns = [
    {
      dataField: "firstname",
      text: "First Name",
    },
    {
      dataField: "lastname",
      text: "Last Name",
    },
    {
      dataField: "date",
      text: "Creation Date",
      formatter: (cell) => {
        if (!cell) return ""; // Handle null or undefined dates
        const date = new Date(cell);
        return date.toLocaleDateString("en-GB"); // dd/MM/yyyy format
      },
    },
    {
      dataField: "matrikelnummer",
      text: "Matriculation Number",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "placeofbirth",
      text: "Place of Birth",
    },
    {
      dataField: "gender",
      text: "Gender",
    },
    {
      dataField: "countryofbirth",
      text: "Country of Birth",
    },
    {
      dataField: "currentfieldofstudy",
      text: "Current Field of Study",
    },
    {
      dataField: "currentdegree",
      text: "Current Degree",
    },
    {
      dataField: "semester",
      text: "Semester",
    },
    {
      dataField: "motivation",
      text: "Motivation",
    },
    {
      dataField: "registeredBefore",
      text: "Registered Before",
    },
    {
      dataField: "previousParticipation",
      text: "Participated Before",
    },
    {
      dataField: "germanlanguagelevel",
      text: "German Language Level",
    },
    {
      dataField: "registeredIn",
      text: "Registered In",
    },
    {
      dataField: "preferredFieldOfBusiness",
      text: "Preferred Field of Business",
    },
  ];

  const exportStudentInformation = () => {
    axios({
      url: `/api/forms/allstudentsexcel`,
      method: "POST",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Registered-Students.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    dispatch(getProfiles());
  }, []);

  return (
    <div className="d-flex flex-column align-items-center p-5">
      <Button
        type="primary"
        style={ButtonStyle}
        onClick={exportStudentInformation}
      >
        Export Students Information in excel
      </Button>
      <AntTable data={profiles} columns={columns} />
    </div>
  );
}

export default ViewStudentProfiles;
