import React, { useState, useEffect } from "react";
import axios from "axios";

const CreateTeam = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [representativeEmail, setRepresentativeEmail] = useState("");
  const [members, setMembers] = useState(Array(5).fill("")); // Array for 5 member emails
  const [message, setMessage] = useState("");

  // Fetch companies from /api/company/allcompanies endpoint
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get("api/company/allcompanies");
        console.log("response data:: " + JSON.stringify(response.data));
        setCompanies(response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, []);

  const handleMemberChange = (index, value) => {
    const updatedMembers = [...members];
    updatedMembers[index] = value;
    setMembers(updatedMembers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/api/team/createTeam", {
        name: selectedCompany,
        representativeEmail,
        members,
      });
      setMessage("Team created successfully!");
    } catch (error) {
      setMessage("Error creating team: " + error.message);
    }
  };

  // Inline styles
  const styles = {
    container: {
      backgroundColor: "#ffffff",
      padding: "2rem",
      maxWidth: "400px",
      width: "90%",
      borderRadius: "8px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      margin: "0 auto",
      marginTop: "1rem",
    },
    heading: {
      marginBottom: "1rem",
      fontSize: "1.5rem",
      color: "#333",
    },
    formGroup: {
      marginBottom: "1rem",
      textAlign: "left",
    },
    label: {
      display: "block",
      marginBottom: "0.5rem",
      fontWeight: "bold",
      color: "#555",
    },
    input: {
      width: "100%",
      padding: "0.5rem",
      fontSize: "1rem",
      border: "1px solid #ddd",
      borderRadius: "4px",
    },
    button: {
      width: "100%",
      padding: "0.7rem",
      fontSize: "1rem",
      color: "#fff",
      backgroundColor: "#64A0C8",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },

    message: {
      marginTop: "1rem",
      color: "#28a745",
      fontWeight: "bold",
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Create a New Team</h2>
      {message && <p style={styles.message}>{message}</p>}
      <form onSubmit={handleSubmit}>
        <div style={styles.formGroup}>
          <label style={styles.label}>Team Name:</label>
          <select
            value={selectedCompany}
            onChange={(e) => setSelectedCompany(e.target.value)}
            required
            style={styles.input}
          >
            <option value="">-- Select Company --</option>
            {companies.map((company) => (
              <option key={company._id} value={company.companyName}>
                {company.companyName}
              </option>
            ))}
          </select>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Representative Email:</label>
          <input
            type="email"
            value={representativeEmail}
            onChange={(e) => setRepresentativeEmail(e.target.value)}
            required
            style={styles.input}
          />
        </div>
        {[...Array(5)].map((_, index) => (
          <div key={index} style={styles.formGroup}>
            <label style={styles.label}>Member {index + 1} Email:</label>
            <input
              type="email"
              value={members[index]}
              onChange={(e) => handleMemberChange(index, e.target.value)}
              required
              style={styles.input}
            />
          </div>
        ))}
        <button
          type="submit"
          style={{
            ...styles.button,
          }}
        >
          Create Team
        </button>
      </form>
    </div>
  );
};

export default CreateTeam;
