import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function HowItWorks() {
  const headingStyle = {
    textAlign: "center",
    fontWeight: 600,
    padding: "40px",
    fontSize: "30px",
    color: "#313131",
  };

  return (
    <section id="how-it-works" style={{ padding: "20px 0" }}>
      <Container>
        <div>
        <h1 style={headingStyle}>How does it work?</h1>
        <Row>
          <Col md={6}>
            <div className="d-flex p-3">
              <p style={{ fontSize: "40px", color: "#DB6C1F", fontWeight: 800 }}>1.</p>
              <p style={{padding: "10px 14px", fontSize: "14px"}}>Up to 5 TUM Talents will spend one week on-site at the company. Get to know the operational processes through interviews, workshops, presentations etc. at the company.</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex p-3">
              <p style={{ fontSize: "40px", color: "#DB6C1F", fontWeight: 800 }}>2.</p>
              <p style={{padding: "10px 14px", fontSize: "14px"}}>The company is in the lead to guide through the week’s agenda, collaborating with the TUM Talents to solve a real-life operational problem.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="d-flex p-3">
              <p style={{ fontSize: "40px", color: "#DB6C1F", fontWeight: 800 }}>3.</p>
              <p style={{padding: "10px 14px", fontSize: "14px"}}>TUM Talents will analyze the issue which has been defined by the company and work on a solution.</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex p-3">
              <p style={{ fontSize: "40px", color: "#DB6C1F", fontWeight: 800 }}>4.</p>
              <p style={{padding: "10px 14px", fontSize: "14px"}}>With a different mindset and perspective, the TUM Talents will present their alternative result solving the problem, discuss and reflect the delta of the approaches with the company.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="d-flex p-3">
              <p style={{ fontSize: "40px", color: "#DB6C1F", fontWeight: 800 }}>5.</p>
              <p style={{padding: "10px 14px", fontSize: "14px"}}>Selected 1.000+ TUM Talents will present their solution on a poster during the final project week event of the TUM.</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex p-3">
              <p style={{ fontSize: "40px", color: "#DB6C1F", fontWeight: 800 }} >6.</p>
              <p style={{padding: "10px 14px", fontSize: "14px"}}>Companies are invited to join this networking event to get in touch with the whole TUM community and its representatives for further initiatives.</p>
            </div>
          </Col>
        </Row>
        </div>
      </Container>
    </section>
  );
}

export default HowItWorks;
