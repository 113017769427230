import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

// Import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const newsItems = [
  {
    image: "/news/Electronica.jpg",
    title: "Electronica",
    description:
      "Young Talents Day Leading the way to your future career. Join the student's day on 15 November at Electronica.",
    link: "https://electronica.de/en/trade-fair/program/careers/young-talents/",
    expirationDate: "2024-11-16",
  },
  {
    image: "/news/Bild_Veranstaltung_TSCF.jpg",
    title: "TUM Student Club Fair - 'Get inspired – get involved'",
    description:
      "October 23rd, 2024: 10 am to 5 pm at Campus Garching. Come in, look around, get inspired. Discover the diversity of student engagement at TUM!",
    link: "https://www.tum.de/en/community/campus-life/student-clubs",
    expirationDate: "2024-10-24",
  },
  {
    image: "/news/CareerLounge.jpg",
    title: "TUM Career Day",
    description:
      "During the TUM Career Days we offer an exciting selection of events: Online CV checks Professional application photos Online Speed Dating sessions with renowned companies Webinars on topics such as job search and salary negotiations Career Lounge with TUM alumni",
    link: "https://www.community.tum.de/en/career-service/career-day-en/",
    expirationDate: "2024-11-14",
  },
  {
    image: "/news/StartingCareerinGermany.jpg",
    title: "Starting a Career in Germany",
    description:
      "Finding your footing in the German job market can feel like navigating a maze, right? Join Online Session with TUM Alumna Simran Khokha, Tuesday, November 19, from 12.00-13.00",
    link: "https://www.community.tum.de/en/event/4945/",
    expirationDate: "2024-11-19",
  },

  {
    // Healthtech Innovation - Insights.jpg
    image: "/news/HealthtechInnovationInsights.png",
    title: "Healthtech Innovation Insights",
    description:
      "It's a digital format which takes place every second Wednesday from 15:30 to 16:30 (in German). Panel discussions or keynote speeches on exciting digital health topics will take place in this digital space.",
    link: "https://emagazin.bayern-innovativ.de/emagazin/detail/de/seite/healthtech-innovation-insights",
    expirationDate: "2024-11-21",
  },
  // {
  //   image: "/news/TagderoffenenTuer_TUM.jpg",
  //   title: "Tag der offenen Tür",
  //   description:
  //     "Experience science in lectures, hands-on activities, guided tours and much more - join us at 'Tag der offenen Tür' - October 3rd at TUM Campus Garching",
  //   link: "https://www.tum.de/en/news-and-events/events/details/open-day-research-campus-garching",
  // },
  {
    image: "/news/Banner_Hackathon.jpg",
    title: "Participate in the Healthcare Hackathon Bavaria 'Quit addictions!'",
    description: "",
    link: "https://www.bayern-innovativ.de/en/event/healthcare-hackathon-bayern-2024",
    expirationDate: "2024-10-25",
  },
  {
    image: "/news/CommunityofPractice.png",
    title:
      "Shaping digitalisation in medical practices and join our Community of Practice!",
    description:
      "Benefit from expert presentations, interactive discussions, and a valuable network. Every first Tuesday of the month from 15:00 to 16:30",
    link: "https://www.bayern-innovativ.de/de/netzwerke-und-thinknet/uebersicht-gesundheit/veranstaltung/digitalisierung-in-arztpraxen10-2024",
    expirationDate: "2024-12-04",
  },
];

const News = () => {
  // Install Swiper modules
  SwiperCore.use([Pagination, Navigation, Autoplay]);

  return (
    <div
      id="news"
      style={{
        margin: "0 auto",
        position: "relative",
        marginTop: "25px",
        backgroundColor: "#fafafa",
        // border: "30px solid #64A0C8",
        // borderRadius: "10px",
      }}
    >
      <h1 style={{ textAlign: "center", marginTop: "10px", fontSize: "30px" }}>
        Upcoming Events
      </h1>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation={false}
        loop={true}
        autoplay={{ delay: 16000 }}
        style={{
          paddingBottom: "40px", // Move the dots down a bit
        }}
      >
        {newsItems.map((slide, index) =>
          //check on expiration date if passed compared to today's date then don't show the slide
          new Date(slide.expirationDate) < new Date() ? null : (
            <SwiperSlide key={index}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center", // Center content horizontally
                  justifyContent: "center", // Center content vertically
                  textAlign: "center", // Center text
                  height: "100%", // Fill the container height
                }}
              >
                {/* Image Section */}

                <img
                  src={slide.image}
                  alt={slide.title}
                  style={{
                    width: "40%", // Adjust width for better responsiveness
                    height: "auto",
                    borderRadius: "10px",
                    marginBottom: "20px", // Add some space between the image and text
                    marginTop: "20px",
                  }}
                />

                {/* Title */}
                <h3
                  style={{
                    width: "40%",
                    fontSize: "20px",
                    marginBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  {slide.title}
                </h3>

                {/* Description */}
                <p
                  style={{
                    width: "40%",
                    fontSize: "16px",
                    marginBottom: "10px",
                  }}
                >
                  {slide.description}
                </p>
                <a href={slide.link}>
                  <button
                    style={{
                      width: "150px",
                      height: "40px",
                      borderRadius: "25px",
                      backgroundColor: "#DB6C1F",
                      color: "white",
                    }}
                  >
                    Read more
                    <FontAwesomeIcon
                      icon={faArrowRight} // Correct usage of icon object
                      style={{ marginLeft: "7px", marginTop: "5px" }} // Adding margin between text and icon
                    />
                  </button>
                </a>
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>

      {/* Custom styles for navigation buttons */}
      <style>
        {`
          .swiper-button-next, .swiper-button-prev {
            color: #000; /* Customize arrow color */
            width: 10px;
            height: 10px;
            top: 350px; 
          }

          .swiper-button-next {
            right: 250px; /* Move right arrow out of the slide */
          }

          .swiper-button-prev {
            left: 250px; /* Move left arrow out of the slide */
          }

          @media (max-width: 768px) {
            .swiper-button-next{
              right: 20px; /* Adjust for smaller screens */
              top: 150px;
            }
            .swiper-button-prev {
              left: 20px; /* Adjust for smaller screens */
              top: 150px;
            }
            
          }
        `}
      </style>
    </div>
  );
};

export default News;
