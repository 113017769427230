import React from "react";
import Accordion from "../../ui/accordion/Accordion";

function FAQS() {
  const headingStyle = {
    textAlign: "center",
    fontWeight: 600,
    padding: "40px",
    fontSize: "30px",
    color: "#313131",
  };

  return (
    <div id="FAQ" style={{ paddingBottom: "60px", backgroundColor: "#fafafa" }}>
      <h1 style={headingStyle}>Frequently Asked Questions</h1>
      <Accordion />
    </div>
  );
}

export default FAQS;
