import React from "react";
import "./Supporters.css"; // Import CSS file for styling
import AutoCarousel from "../../ui/auto-carousel/AutoCarousel";

const Supporters = () => {

  const logos = [
    {
      name: "Blanc Fischer",
      src: "companyLogos/BFCS.png",
      url: "https://www.blanc-fischer.com/"
    },
    {
      name: "Airbus",
      src: "companyLogos/AirBus1.png",
      url: "https://www.airbus.com"
    },
    {
      name: "FoQee",
      src: "companyLogos/FoQee1.png",
      url: "https://www.foqee.de/"
    },
    {
      name: "INDYON",
      src: "companyLogos/INDYON1.png",
      url: "https://www.indyon.com"
    },
    {
      name: "InnoRoute",
      src: "companyLogos/InnoRoute.png",
      url: "https://innoroute.com/"
    },
    {
      name: "MBBM",
      src: "companyLogos/MBBM.png",
      url: "https://www.muellerbbm.com/homepage/"
    },
    {
      name: "Peri",
      src: "companyLogos/PERI1.png",
      url: "https://www.peri.com"
    },
    {
      name: "RUSC",
      src: "companyLogos/RUSC.png",
      url: "https://www.runds.de"
    },
    {
      name: "ScioSpec",
      src: "companyLogos/ScioSpec.png",
      url: "https://www.sciospec.com/"
    },
    {
      name: "VitaScale",
      src: "companyLogos/VitaScale1.png",
      url: "https://vitascale.com/"
    },
    {
      name: "WE_MTYE",
      src: "companyLogos/WE_MTYE.png",
      url: "https://www.we-online.com"
    },
    {
      name: "BlueSolve",
      src: "companyLogos/BlueSolve.png",
      url: "https://www.bluesolve.ai/"
    },
    {
      name: "WFG",
      src: "companyLogos/WFG.png",
      url: "https://wfgheilbronn.de/"
    }
  ];

  return (
    <div id="Supporters" style={{ padding: "20px 0px" }}>
      <AutoCarousel logos={logos}/>
    </div>
  );
};

export default Supporters;