import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useDispatch } from "react-redux";

function ViewTeams() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Loading state
  const [tableData, setTableData] = useState([]); // Table data state

  const headerStyle = {
    textAlign: "center",
    marginTop: "20px",
  };

  // Fetch all teams
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/api/team/getAllTeams");

        if (res.status >= 400) throw new Error("Error fetching teams");
        setTableData(res.data);
        console.log("response data:: " + JSON.stringify(res.data));
      } catch (error) {
        console.error("Error fetching teams:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const columns = [
    { text: "Team Name", dataField: "name" },
    { text: "Team Representative Email", dataField: "representativeEmail" },
    { dataField: "members[0]", text: "Member 1 Email" },
    { dataField: "members[1]", text: "Member 2 Email" },
    { dataField: "members[2]", text: "Member 3 Email" },
    { dataField: "members[3]", text: "Member 4 Email" },
    { dataField: "members[4]", text: "Member 5 Email" },
  ];

  // Function to access nested values
  const getNestedValue = (obj, path) => {
    return path
      .split(".")
      .reduce((value, key) => (value ? value[key] : ""), obj);
  };

  if (loading) return <div>Loading...</div>;
  if (!tableData.length) return <div>No Data Available</div>;

  return (
    <div>
      <h2 style={headerStyle}>View All Teams</h2>
      <TableContainer component={Paper} className="mt-5">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell key={col.dataField} align="center">
                  {col.text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((col) => (
                  <TableCell key={col.dataField} align="center">
                    {getNestedValue(
                      row,
                      col.dataField.replace(/\[(\d+)\]/g, ".$1")
                    ) || ""}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ViewTeams;
