import React from "react";
import AntCarousel from "../../ui/carousel/Carousel";

const Testimonials = () => {
  return (
    <div style={{ backgroundColor: "#FaFaFa", padding: "20px 0px" }}>
      <h1
        style={{
          textAlign: "center",
          fontWeight: 600,
          fontSize: "24px",
          color: "#535353",
          padding: "20px 0px",
        }}
      >
        What people say about us
      </h1>
      <div className="custom-container">
        <div>
          <AntCarousel />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
