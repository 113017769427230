import React, { useState } from "react";
import Button from "../../ui/button/Button";
import { useHistory } from "react-router-dom";
// import config from "../../../config";
// import axios from "axios";
// import { Modal } from "antd";

const StudentProfileActions = ({ state }) => {
  const history = useHistory();
  const [uploadAttachment, setUploadAttachment] = useState();
  const [uploadError, setUploadError] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleBack = () => {
    history.push("/");
  };

  // const uploadFile = async (formsData) => {
  //   try {
  //     const response = await axios.post(`api/profile/uploadfile`, formsData);
  //     return response.status;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleUpload = async (e) => {
  //   e.preventDefault();
  //   const fileData = new FormData();
  //   fileData.append("file", uploadAttachment);
  //   const status = await uploadFile(fileData);
  //   if (status === 201) {
  //     setUploadSuccess(true);
  //   } else {
  //     setUploadError(
  //       "File upload failed: Please try again by choosing the file once more. Please ensure that the file is below 10 MB"
  //     );
  //   }

  //   setUploadAttachment("");
  // };

  // const uploadToClient = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     const i = event.target.files[0];
  //     setUploadAttachment(i);
  //   }
  // };

  return (
    <div>
      <div style={{ overflowY: "auto" }}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "100px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "30px",
              }}
            >
              <h3 style={{ textAlign: "center", paddingBottom: "5px" }}>
                You are already registered with us.{" "}
              </h3>
              <h3 style={{ textAlign: "center" }}>
                Please wait for the matching to end. Thank you
              </h3>
            </div>

            <Button
              text="GO BACK TO HOMEPAGE"
              variant="primary"
              onClick={handleBack}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProfileActions;
