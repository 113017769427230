import React, { useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { setCurrentUser } from "../../actions/authActions.js";

function SSOLogin() {
    // state is needed, because useEffect is called after the redirect
    const [userIsSet, setUserIsSet] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const id = query.get("id");
        const role = query.get("role");
        const exp = query.get("exp");
        const firstname = query.get("firstname");
        const lastname = query.get("lastname");
        const matrikelnummer = query.get("matrikelnummer");
        const email = query.get("email");
        const placeofbirth = query.get("placeofbirth");
        const countryofbirth = query.get("countryofbirth");
        const currentfieldofstudy = query.get("currentfieldofstudy");

        if (id && role && exp && parseInt(exp) * 1000 > Date.now()) {
            localStorage.setItem("user", JSON.stringify({ id, role, exp }));
            dispatch(setCurrentUser({ id, role, firstname, lastname, matrikelnummer, email, placeofbirth, countryofbirth, currentfieldofstudy }));

            setUserIsSet(true)
        }
    }, [location, dispatch]);

    return (userIsSet ? <Redirect to="/dashboard" /> : null);
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(SSOLogin);
