import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";

function ProjectWeekInfo() {
  const headingStyle = {
    textAlign: "center",
    fontWeight: 600,
    padding: "40px",
    fontSize: "30px",
    color: "#313131",
  };

  const leftpWithBorder = {
    borderLeft: "5px solid #DB6C1F", // Add a 3px solid left border
    padding: "20px",
    margin: "10px",
  };

  const rightpWithBorder = {
    borderRight: "5px solid #64A0C8", // Add a 3px solid left border
    padding: "20px",
    textAlign: "right",
    margin: "10px",
  };

  return (
    <>
      <section id="project-week-info" style={{ backgroundColor: "#FAFAFA" }}>
        <div className="container">
          <div>
            <h1 style={headingStyle}>What is 1.000+ Project Week all about?</h1>
            <div>
              <div style={leftpWithBorder}>
                <p style={{ margin: "0px" }}>
                  On the one side we have a diverse community of 52.000 TUM
                  Talents (44% with international background), who are enrolled
                  in 178 different{" "}
                  <a href="https://www.tum.de/studium/studienangebot">
                    courses
                  </a>{" "}
                  and eager to solve real-world challenges (challenge-based
                  learning).
                </p>
              </div>
              <div style={rightpWithBorder}>
                <p style={{ margin: "0px" }}>
                  On the other side, Hidden Champions, especially Small and
                  Medium-sized Enterprises (SME), who represent 99% of all
                  German companies, are THE country’s economic engine. However,
                  they have limited opportunities to engage and connect with the
                  pool of TUM Talents.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="how-it-works" style={{ padding: "20px 0" }}>
        <Container>
          <div>
            <h1 style={headingStyle}>How does it work?</h1>
            <Row>
              <Col md={6}>
                <div className="d-flex p-3">
                  <p
                    style={{
                      fontSize: "40px",
                      color: "#DB6C1F",
                      fontWeight: 800,
                    }}
                  >
                    1.
                  </p>
                  <p style={{ padding: "10px 14px", fontSize: "14px" }}>
                    Up to 5 TUM Talents will spend one week on-site at the
                    company. Get to know the operational processes through
                    interviews, workshops, presentations etc. at the company.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="d-flex p-3">
                  <p
                    style={{
                      fontSize: "40px",
                      color: "#DB6C1F",
                      fontWeight: 800,
                    }}
                  >
                    2.
                  </p>
                  <p style={{ padding: "10px 14px", fontSize: "14px" }}>
                    The company is in the lead to guide through the week’s
                    agenda, collaborating with the TUM Talents to solve a
                    real-life operational problem.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="d-flex p-3">
                  <p
                    style={{
                      fontSize: "40px",
                      color: "#DB6C1F",
                      fontWeight: 800,
                    }}
                  >
                    3.
                  </p>
                  <p style={{ padding: "10px 14px", fontSize: "14px" }}>
                    TUM Talents will analyze the issue which has been defined by
                    the company and work on a solution.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="d-flex p-3">
                  <p
                    style={{
                      fontSize: "40px",
                      color: "#DB6C1F",
                      fontWeight: 800,
                    }}
                  >
                    4.
                  </p>
                  <p style={{ padding: "10px 14px", fontSize: "14px" }}>
                    With a different mindset and perspective, the TUM Talents
                    will present their alternative result solving the problem,
                    discuss and reflect the delta of the approaches with the
                    company.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="d-flex p-3">
                  <p
                    style={{
                      fontSize: "40px",
                      color: "#DB6C1F",
                      fontWeight: 800,
                    }}
                  >
                    5.
                  </p>
                  <p style={{ padding: "10px 14px", fontSize: "14px" }}>
                    Selected 1.000+ TUM Talents will present their solution on a
                    poster during the final project week event of the TUM.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="d-flex p-3">
                  <p
                    style={{
                      fontSize: "40px",
                      color: "#DB6C1F",
                      fontWeight: 800,
                    }}
                  >
                    6.
                  </p>
                  <p style={{ padding: "10px 14px", fontSize: "14px" }}>
                    Companies are invited to join this networking event to get
                    in touch with the whole TUM community and its
                    representatives for further initiatives.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section style={{ backgroundColor: "#fafafa" }}>
        <div className="container">
          <div
            style={{
              paddingTop: "50px",
              paddingBottom: "30px",
            }}
          >
            <h1 style={headingStyle}>Why sign up for 1.000+ Project Week?</h1>
            <div className="grid-container py-3">
              <div className="grid-item p-3">
                <span
                  style={{
                    fontSize: "20px",
                    color: "#64A0C8",
                    paddingRight: "10px",
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                </span>
                <p>Preparing future careers of Master students</p>
              </div>
              <div class="grid-item p-3">
                <span
                  style={{
                    fontSize: "20px",
                    color: "#64A0C8",
                    paddingRight: "10px",
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                </span>
                <p>Learning about industrial culture of Hidden Champions</p>
              </div>
              <div class="grid-item p-3">
                <span
                  style={{
                    fontSize: "20px",
                    color: "#64A0C8",
                    paddingRight: "10px",
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                </span>
                <p>
                  Integrating German and International TUM Talents in the
                  domestic job market
                </p>
              </div>
              <div class="grid-item p-3">
                <span
                  style={{
                    fontSize: "20px",
                    color: "#64A0C8",
                    paddingRight: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                </span>
                <p>
                  Fueling the German innovation engine with excellent engineers
                </p>
              </div>
              <div class="grid-item p-3">
                <span
                  style={{
                    fontSize: "20px",
                    color: "#64A0C8",
                    paddingRight: "10px",
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                </span>
                <p>
                  Connecting founders and Hidden Champions with our TUM talents
                  who are passionate about technology and innovation
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h1
          style={{
            textAlign: "center",
            fontWeight: 600,
            fontSize: "28px",
            backgroundColor: "#FAFAFA",
            color: "#DB6C1F",
            padding: "50px 30px",
          }}
        >
          Our goal is to connect ​1.000+ TUM Talents with the <br /> Hidden
          Champions
        </h1>
      </section>
    </>
  );
}

export default ProjectWeekInfo;
