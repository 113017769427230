import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Typography, Checkbox } from "antd";
import { Link } from "react-router-dom";
import Button from "../../ui/button/Button";

const { Title, Text } = Typography;

export default function Terms() {
  const [role, setRole] = useState("student");
  const [tc, setTC] = useState(false);
  const [msg, setMsg] = useState("");
  const history = useHistory();
  const [profile, setProfile] = useState();

  useEffect(() => {
    axios
      .get("/api/profile")
      .then((res) => setProfile(res.data))
      .catch((err) => console.log(err));

  }, [profile, history]);

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleTCChange = (e) => {
    setTC(!tc);
  };

  const handleMsgChange = (e) => {
    setMsg("* Please accept Terms and Conditions");
  };

  const createProfileButton = (
    <Button
      text="Create Profile"
      variant="secondary"
      onClick={handleMsgChange}
      style={{ color: "white" }}
    />
  );

  return (
    <div className="container">
      <div>
        <h1 style={{ textAlign: "center", padding: "30px" }}>
          Geschäftsbedingungen / Terms and Conditions
        </h1>
        <div
          style={{
            height: "50vh",
            width: "100%",
            border: "1px solid #d3d3d3",
            overflow: "auto",
            padding: "30px",
            margin: "auto",
          }}
        >
          <div>
            <strong>Datenspeicherrichtlinie / Data Storage Policy</strong>
            <br />
            <br />
            Von den Studierenden speichern wir folgende Daten:{" "}
            <strong>
              Vorname, Nachname, E-Mail-Adresse, Geburtsort, Nationalität,
              Geschlecht, aktuelles Semester und aktueller Abschluss.
            </strong>{" "}
            Diese Daten sind für den Matchmaking-Prozess notwendig.
            <br />
            <br />
            <strong>English Version</strong>
            <br />
            For the students we store the following data:{" "}
            <strong>
              first name, last name, email address, place of birth, country of birth,
              gender, current semester and current degree.
            </strong>{" "}
            This data is necessary for the match making process.
            <br />
            <br />
            <strong>Einwilligungserkärung / Statement of Consent</strong>
            <br />
            <br />
            Ich stimme zu, dass meine angegebenen personenbezogenen Daten für
            den Tutorbetrieb durch die Verwaltung der Technischen Universität
            verarbeitet werden. Ich kann meine Einwilligung jederzeit für die
            Zukunft widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der
            Einwilligung bis zum Widerruf erfolgten Verarbeitung auf Grundlage
            von Art. 6 Abs. 1 lit. a DSGVO berührt wird.
            <br />
            <br />
            Ihren Widerruf richten Sie bitte an 1000plus@cit.tum.de. Unter den
            gesetzlichen Voraussetzungen besteht ein Recht auf Auskunft, sowie
            auf Berichtigung oder Löschung oder auf Einschränkung der
            Verarbeitung oder eines Widerspruchsrechts gegen die Verarbeitung
            sowie des Rechts auf Datenübertragbarkeit. Es besteht zudem ein
            Beschwerderecht beim Bayerischen Landesbeauftragten für den
            Datenschutz. Bei Fragen können Sie Sich gerne an uns
            (1000plus@cit.tum.de).
            <br />
            <br />
            <strong>English Version</strong>
            <br />
            I give my consent to the processing of the provided privacy relevant
            data for the tutor operation by the administration of the Technical
            University of Munich. I can revoke my agreement at any time for the
            future, without affecting the legitimacy of the data processing
            until the revocation on the basis of article 6 section 1 lit. a
            GDPR.
            <br />
            <br />
            In case of cancellation please direct your request to
            1000plus@cit.tum.de. Among the legal preconditions there exists a
            right for disclosure, correction or deletion or for limitation of
            the processing or a right for revocation against the processing as
            well as the right for data transferability. In addition, there is a
            right for complaint at the Bavarian State Delegate for Data
            Protection. In case of questions feel free to contact us
            (1000plus@cit.tum.de).
          </div>
          <br />
          <br />
          {/*  */}
          <div>
            <strong>Teilnahmebedingung / Conditions of Participation</strong>
            <br />
            <br />
            <div>
              Match-Making: Mit der Anmeldung geht der Teilnehmer die Verpflichtung ein an der Projektwoche 1.000+ teilzunehmen. Das Match-Making zwischen Studenten und Firmenpartner erfolgt nach Ende der Anmeldefrist gemäß der Präferenzen. Eine Garantie für einen Teamplatz kann nicht gegeben werden, da diese von der Teilnehmerzahl von Studenten und Firmen abhängt.
              <br />
              <br />
              Kostenübernahme: Die TUM übernimmt die Aufwände für die Organisation der Projektwoche und die Abschlussveranstaltung. Die Firmenpartner übernehmen die Kosten für Transport, Übernachtung und Verpflegung, falls sich der Veranstaltungsort außerhalb des Studienortes befindet.
              <br />
              <br />
              Betreuung: Für die Projektwoche übernimmt der Firmenpartner die Verantwortung das Team über ein reales - in der Vergangenheit gelöstes - betriebliches Problem zu unterrichten und dieses am Ende der Woche aufzulösen. Im Falle einer benötigten Vertraulichkeitsvereinbarung sind die Vertragspartner die Studenten und der Firmenpartner.
              <br />
              <br />
              Ziel des Projekts: Das Ziel von Challenge-Based Learning wird erreicht durch die Erfahrung der Problemstellung und dem Kennnenlernen der betrieblichen Abläufe. Die Lösung des Studenten Teams erfolgt durch eine fimeninterne Präsentation sowie durch das Hochladen des Posters pro Team auf der website von 1.000+ am Ende der Projektwoche. Die Poster werden im Rahmen einer gemeinsamen Abschlussveranstaltung aller Projektwochen ausgestellt.
            </div>
            <br />
            <br />
            <strong>English Version</strong>
            <br />
            <br />
            Match-making: By registering, the participant undertakes the commitment to participate in Project Week 1,000+. The matching between students and the corporate partner will conducted regarding the preferences after the registration deadline. There is no guarantee of a spot on a team as it depends on the number of participants from both the students and the companies.
            <br />
            <br />
            Cost coverage: TUM will bear the expenses for organizing Project Week and the closing event. The corporate partners will cover the costs for transportation and hosting if the event takes place outside the location of the study.
            <br />
            <br />
            Support: For Project Week, the corporate partner takes responsibility for instructing the team on a real operational problem that has been solved in the past and resolving it at the end of the week. In the event of a necessary confidentiality agreement, the contracting parties are the students and the corporate partner.
            <br />
            <br />
            Target of the project: The goal of Challenge-Based Learning is achieved through the experience of problem-solving and familiarizing with the operational procedures. The solution by the student team will be presented internally within the company as well as uploading the Team poster via our 1.000+ website at the end of the Project Week. The posters will be exhibited during a joint closing event for all Project Weeks of TUM.
          </div>
        </div>
        <br />
        <div className="text-center">
          <Checkbox id="tc" name="tc" value="agree" onChange={handleTCChange}>
            <Text strong>Accept Terms and Conditions</Text>
          </Checkbox>
          <Text type="danger" style={{ marginBottom: ".25rem" }}>
            {msg}
          </Text>
        </div>
        <br />
        <div className="text-center">
          {!tc &&
            // Use the createProfileButton component
            (role === "student" ? createProfileButton : createProfileButton)}
          {tc && (
            // Use Ant Design Button with Link for Create Profile
            <Link
              to={{
                pathname:
                  role === "student"
                    ? "/create-profile"
                    : "/create-advisorprofile",
                state: { desiredrole: role },
              }}
            >
              {createProfileButton}
            </Link>
          )}
        </div>
        <br />
        <br />
      </div>
    </div>
  );
}
