import React from 'react';

const SignupButton = ({ text, variant, onClick }) => {
  const buttonClass = variant === 'primary' ? 'primary' : 'secondary';

  return (
    <button className={`border-button ${buttonClass}`} style={{color: 'white'}} onClick={onClick}>
      {text}
    </button>
  );
};

export default SignupButton;