import React, { useState } from "react";
import Button from "../../ui/button/Button";
import { useHistory } from "react-router-dom";
import config from "../../../config";
import axios from "axios";
import { Modal } from "antd";

export const uploadFile = async (formsData) => {
  try {
    const response = await axios.post(`api/profile/uploadfile`, formsData);
    return response.status;
  } catch (error) {
    console.error(error);
  }
};

const TestUploadAdmin = ({ state }) => {
  const history = useHistory();
  const [uploadAttachment, setUploadAttachment] = useState();
  const [uploadError, setUploadError] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleBack = () => {
    history.push("/");
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const fileData = new FormData();
    fileData.append("file", uploadAttachment);
    const status = await uploadFile(fileData);
    console.log(status);
    if (status === 201) {
      setUploadSuccess(true);
    } else {
      setUploadError(
        "An error has occured. Please try again after selecting it again"
      );
    }

    setUploadAttachment("");
  };

  const uploadToClient = (event) => {
    if (event.target.files && event.target.files[0]) {
      const i = event.target.files[0];
      setUploadAttachment(i);
    }
  };

  return (
    <div>
      <div style={{ overflowY: "auto" }}>
        <div>
          {state ? (
            <h1>Congratulations! You have submitted us your data</h1>
          ) : config.featureFlags.showStudentLoginButton ? (
            <>
              <div>
                <div style={{ paddingTop: "40px" }}>
                  <h1>THANKYOU FOR TAKING PART IN 1.000+ PROJECT WEEK</h1>
                  <h5>
                    Please upload your poster by first choosing the file and
                    then pressing submit
                  </h5>
                  <p>
                    Ensure that you follow the file naming format:
                    groupname_company (e.g group5_infineon.pdf)
                  </p>
                </div>

                <form onSubmit={handleUpload}>
                  <input
                    type="file"
                    className="text-sm text-stone-500"
                    name="attachment"
                    onChange={uploadToClient}
                  />
                  {uploadError === "" ? null : <p>{uploadError}</p>}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    <input
                      type="submit"
                      value="SUBMIT"
                      className={`rounded-button primary`}
                    />
                    <Button
                      text="GO BACK TO HOMEPAGE"
                      variant="secondary"
                      style={{
                        color: "white",
                        backgroundColor: "grey",
                        fontWeight: "600",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                      onClick={handleBack}
                    />
                  </div>
                </form>
                <Modal
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px 0px",
                        textAlign: "center",
                      }}
                    >
                      <h2 style={{ color: "#DB6C1F" }}>
                        POSTER UPLOADED SUCCESSFULLY
                      </h2>
                    </div>
                  }
                  open={uploadSuccess}
                  closable={false}
                  keyboard={false}
                  footer={[
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        padding: "10px 0px",
                      }}
                    >
                      <Button
                        text="GO BACK TO HOMEPAGE"
                        onClick={handleBack}
                        variant={"secondary"}
                        style={{ color: "white" }}
                      />
                    </div>,
                  ]}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    Thankyou for submitting your poster to us
                  </p>
                </Modal>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "100px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "30px",
                  }}
                >
                  <h3 style={{ textAlign: "center", paddingBottom: "5px" }}>
                    YOU ARE ALREADY REGISTERED WITH US.{" "}
                  </h3>
                  <h3 style={{ textAlign: "center" }}>
                    PLEASE WAIT FOR THE MATCHING PERIOD TO END. THANK YOU
                  </h3>
                </div>

                <Button
                  text="GO BACK TO HOMEPAGE"
                  variant="primary"
                  onClick={handleBack}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestUploadAdmin;
