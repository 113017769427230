import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorsReducer from "./errorsReducer";
import profileReducer from "./profileReducer";
import applicationReducer from "./applicationReducer";
import mailReducer from "./mailReducer";
import triggerReducer from "./triggerReducer";

export default combineReducers({
  application: applicationReducer,
  auth: authReducer,
  errors: errorsReducer,
  mail: mailReducer,
  profile: profileReducer,
  trigger: triggerReducer,
});
