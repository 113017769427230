import React from "react";
import Button from "../../ui/button/Button";
import { useHistory } from "react-router-dom";

export default function Unregistered() {
  const history = useHistory();

  const handleBack = () => {
    history.push("/");
  };

  return (
    <>
      <div>
        <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
          <h1 style={{ color: "#DB6C1F" }}>
            Registration deadline had passed!
          </h1>
          <h5>
            We are sorry to inform you that the signup phase for students is
            over. We are happy to see you next year.
          </h5>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <Button
            text="GO BACK TO HOMEPAGE"
            variant="secondary"
            style={{
              color: "white",
              backgroundColor: "grey",
              fontWeight: "600",
              fontSize: "14px",
              fontWeight: "bold",
            }}
            onClick={handleBack}
          />
        </div>
      </div>
    </>
  );
}
