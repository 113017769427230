import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { startMatching } from "../../../actions/applicationActions";
import axios from "axios";
import { Button } from "antd";
import * as XLSX from "xlsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

function StartMatchingResults() {
  const dispatch = useDispatch();

  const matchedStudents =
    useSelector((state) => state.application.matchingData) || [];

  const ButtonStyle = {
    backgroundColor: "#64A0C8",
    color: "white",
    borderRadius: "4px",
    marginBottom: "20px",
  };

  const exportToExcel = (data) => {
    const excelData = data.map((entry) => {
      return {
        "Company Name": entry.companyName,
        "Number of Matched Students": entry.studentCount,
        "First Name": entry.firstname,
        "Last Name": entry.lastname,
        "Matriculation Number": entry.matrikelnummer,
        Email: entry.email,
        "Place of Birth": entry.placeofbirth,
        "Current Degree": entry.currentdegree,
        Gender: entry.gender,
        "Country of Birth": entry.countryofbirth,
        "Matched Field of Study": entry.currentfieldofstudy,
        Semester: entry.semester,
        "Registered Before": entry.registeredBefore,
        "Participated Before": entry.previousParticipation,
        "German Language Level": entry.germanlanguagelevel,
        "Registered In": entry.registeredIn,
        Motivation: entry.motivation,
      };
    });
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Matched Students and Companies");
    XLSX.writeFile(wb, "Matched-Students-Companies.xlsx");
  };

  const flattenData = (data) => {
    const flattened = [];
    const companyMap = {};

    data.forEach((company) => {
      const companyName = company.companyName;

      if (!companyMap[companyName]) {
        companyMap[companyName] = {
          companyName,
          studentCount: company.students.length,
          students: [...company.students],
        };
      } else {
        companyMap[companyName].studentCount += company.students.length;
        companyMap[companyName].students.push(...company.students);
      }
    });

    Object.values(companyMap).forEach((company) => {
      company.students.forEach((student) => {
        flattened.push({
          companyName: company.companyName,
          studentCount: company.studentCount,
          firstname: student.firstname,
          lastname: student.lastname,
          matrikelnummer: student.matrikelnummer,
          email: student.email,
          placeofbirth: student.placeofbirth,
          currentdegree: student.currentdegree,
          gender: student.gender,
          countryofbirth: student.countryofbirth,
          currentfieldofstudy: student.currentfieldofstudy,
          semester: student.semester,
          motivation: student.motivation,
          registeredBefore: student.registeredBefore,
          previousParticipation: student.previousParticipation,
          germanlanguagelevel: student.germanlanguagelevel,
          registeredIn: student.registeredIn,
        });
      });
    });

    return flattened;
  };

  const columns = [
    { dataField: "companyName", text: "Company Name" },
    { dataField: "studentCount", text: "Number of Matched Students" },
    { dataField: "firstname", text: "First Name" },
    { dataField: "lastname", text: "Last Name" },
    { dataField: "matrikelnummer", text: "Matriculation Number" },
    { dataField: "email", text: "Email" },
    { dataField: "placeofbirth", text: "Place of Birth" },
    { dataField: "currentdegree", text: "Current Degree" },
    { dataField: "gender", text: "Gender" },
    { dataField: "countryofbirth", text: "Country of Birth" },
    { dataField: "currentfieldofstudy", text: "Matched Field of Study" },
    { dataField: "semester", text: "Semester" },
    { dataField: "registeredBefore", text: "Registered Before" },
    { dataField: "previousParticipation", text: "Participated Before" },
    { dataField: "germanlanguagelevel", text: "German Language Level" },
    { dataField: "registeredIn", text: "Registered In" },
    //{ dataField: "motivation", text: "Motivation" },
  ];

  useEffect(() => {
    dispatch(startMatching());
  }, [dispatch]);

  const tableData = flattenData(matchedStudents);
  console.log("table data:: " + JSON.stringify(tableData));

  if (tableData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="d-flex flex-column align-items-center p-5">
      <Button
        type="primary"
        style={ButtonStyle}
        onClick={() => exportToExcel(tableData)}
      >
        Export Matched Students and Companies Information in Excel
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" style={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              {columns.map((col, index) => (
                <TableCell
                  key={col.dataField}
                  align="center"
                  style={{
                    width: "190px",
                    fontWeight: 700, // Make text bold
                    backgroundColor: "#f2f2f2", // Background color
                    color: "#000", // Text color
                    borderBottom: "2px solid #000", // Custom border
                    borderTop: "2px solid #000", // Custom border
                    //i wnat to have border left for the first cell
                    borderLeft: index === 0 ? "2px solid #000" : "none",
                    //i wnat to have border right for the last cell
                    borderRight:
                      index === columns.length - 1 ? "2px solid #000" : "none",
                  }}
                >
                  {col.text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                {columns.map((col, index) => (
                  <TableCell
                    key={col.dataField}
                    align="center"
                    style={{
                      borderBottom: "2px solid #000", // Custom border
                      borderTop: "2px solid #000", // Custom border
                      borderLeft: index === 0 ? "2px solid #000" : "none",
                      borderRight:
                        index === columns.length - 1
                          ? "2px solid #000"
                          : "none",
                    }}
                  >
                    {row[col.dataField] + ""}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default StartMatchingResults;
