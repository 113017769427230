import React from "react";
import ProjectWeek from "../../sections/project-week/ProjectWeek";
import Testimonials from "../../sections/testimonials/Testimonials";
import WhyAttend from "../../sections/why-attend/WhyAttend";
import Timeline from "../../sections/timeline/Timeline";
import FAQS from "../../sections/faqs/FAQS";
import HowItWorks from "../../sections/how-it-works/HowItWorks";
import ProjectWeekInfo from "../../sections/project-week-info/ProjectWeekInfo";
import Insights from "../../sections/insights/Insights";
import Supporters from "../../sections/supporters/Supporters";
import News from "../../sections/news/News";

export default function LoginView() {
  return (
    <div className="login">
      <ProjectWeek />
      <Supporters />
      <ProjectWeekInfo />
      {/* removed how it works section to ProjectInfo page as they are one entity now */}
      {/* <HowItWorks /> */}
      <Insights />
      <Testimonials />
      <WhyAttend />
      <Timeline />
      <News />
      <FAQS />
    </div>
  );
}
