import React from "react";
import { Collapse } from "antd";

const leftitems = [
  {
    key: "1",
    label: "When does the project week 1.000+ take place?",
    children: (
      <p>
        Our young TUM Talents will be on-site at the company on January 13th-17th, 2025.
      </p>
    ),
  },
  {
    key: "2",
    label: "How does the matching work?",
    children: (
      <p>
        The match making will depend on the number of participating students
        and companies, as well as their preferences and will be performed by the organizer of 1.000+.
      </p>
    ),
  },
  {
    key: "3",
    label: "When will we be informed about my group / company?",
    children: (
      <p>
        The matching will take place in November. We will inform both TUM
        Talents & Companies by Email.
      </p>
    ),
  },
  {
    key: "4",
    label: "Is there a template for the poster available?",
    children: (
      <p>
        Yes, we will provide attending TUM Talents with the template.
      </p>
    ),
  },
  {
    key: "5",
    label: "When do we need to submit the poster and where?",
    children: (
      <p>
        Please upload the poster on January 17th, 2024; you will be provided with a link during the project week.
      </p>
    ),
  },
  {
    key: "6",
    label: "Do we need a contract with the company?",
    children: (
      <p>The companies may decide on an NDA contract with individual students.</p>
    ),
  },
];

const rightitems = [
  {
    key: "1",
    label: "How many credits do we get for the project week 1.000+?",
    children: (
      <p>The project week is worth 2 credits, depending on your school.</p>
    ),
  },
  {
    key: "2",
    label: "Who is allowed to participate?",
    children: (
      <p>
        All TUM Master students are welcome. All the companies are welcome to participate in this project.
      </p>
    ),
  },
  {
    key: "3",
    label: "From which disciplines do the students come?",
    children: <p>1.000+ is for all students of all disciplines at TUM.</p>,
  },
  {
    key: "4",
    label: "Who takes over the travel expenses?",
    children: (
      <p>
        It’s the company that covers the costs of the student group. This
        includes coverage of travel and hosting expenses for the whole week.
      </p>
    ),
  },
  {
    key: "5",
    label: "How many students are in one team?",
    children: (
      <p>
        One team will consist of 5 TUM Talents, depending on the number of applicants.
      </p>
    ),
  },
];

const LeftAccordion = () => (
  <Collapse ghost >
    {leftitems.map((item) => (
      <Collapse.Panel key={item.key} header={item.label}>
        {item.children}
      </Collapse.Panel>
    ))}
  </Collapse>
);

const RightAccordion = () => (
  <Collapse ghost>
    {rightitems.map((item) => (
      <Collapse.Panel key={item.key} header={item.label}>
        {item.children}
      </Collapse.Panel>
    ))}
  </Collapse>
);

const Accordion = () => (
  <div className="container">
    <div className="left-section">
      <LeftAccordion />
    </div>
    <div className="right-section">
      <RightAccordion />
    </div>
  </div>
);

export default Accordion;
