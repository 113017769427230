import React, { useState, useEffect } from "react";
import axios from "axios";

const UploadPosters = () => {
  const [file, setFile] = useState(null);
  const [teamName, setTeamName] = useState("");
  const [teams, setTeams] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get("/api/team/getuserTeam");
        setTeams(response.data);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleTeamChange = (e) => {
    setTeamName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file || !teamName) {
      setMessage("Please select a file and a team.");
      return;
    }

    let formData = new FormData();
    formData.append("teamName", teamName);
    formData.append("file", file);

    try {
      const response = await axios.post("/api/upload/uploadPoster", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage("File uploaded successfully!");
    } catch (error) {
      setMessage("File upload failed: " + error.message);
    }
  };

  // Inline styles
  const styles = {
    container: {
      backgroundColor: "#ffffff",
      padding: "2rem",
      maxWidth: "400px",
      width: "90%",
      borderRadius: "8px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      margin: "0 auto",
      marginTop: "1rem",
    },
    heading: {
      marginBottom: "1rem",
      fontSize: "1.5rem",
      color: "#333",
    },
    formGroup: {
      marginBottom: "1rem",
      textAlign: "left",
    },
    label: {
      display: "block",
      marginBottom: "0.5rem",
      fontWeight: "bold",
      color: "#555",
    },
    input: {
      width: "100%",
      padding: "0.5rem",
      fontSize: "1rem",
      border: "1px solid #ddd",
      borderRadius: "4px",
    },
    button: {
      width: "100%",
      padding: "0.7rem",
      fontSize: "1rem",
      color: "#fff",
      backgroundColor: "#64A0C8",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },

    message: {
      marginTop: "1rem",
      color: "#28a745",
      fontWeight: "bold",
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Upload Poster</h2>
      {message && <p style={styles.message}>{message}</p>}
      <form onSubmit={handleSubmit}>
        <div style={styles.formGroup}>
          <label style={styles.label}>Select a team:</label>
          <select
            value={teamName}
            onChange={handleTeamChange}
            required
            style={styles.input}
          >
            <option value="">-- Select Team --</option>
            {teams.map((team) => (
              <option key={team._id} value={team.name}>
                {team.name}
              </option>
            ))}
          </select>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Select file (PDF):</label>
          <input
            type="file"
            onChange={handleFileChange}
            accept="application/pdf, image/*"
            required
            style={styles.input}
          />
        </div>
        <button
          type="submit"
          style={{
            ...styles.button,
          }}
        >
          Upload
        </button>
      </form>
    </div>
  );
};

export default UploadPosters;
