import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "antd";

const ViewImages = () => {
  const [posters, setPosters] = useState([]);
  const [message, setMessage] = useState("");
  const [downloadLink, setDownloadLink] = useState("");

  useEffect(() => {
    const fetchPosters = async () => {
      try {
        const response = await axios.get("/api/upload/getImages");
        setPosters(response.data);
      } catch (error) {
        setMessage("Failed to load posters: " + error.message);
      }
    };
    fetchPosters();
    //for each poster append download link. extract the base link and append ?export=download&id=FILE_ID
    posters.forEach((poster) => {
      const splitLink = poster.fileLink.split("file/d/");
      poster.downloadLink =
        splitLink[0] + "uc?export=download&id=" + poster.fileId;
    });

    console.log("Posters:: " + JSON.stringify(posters));
  }, []);

  const openPreview = (fileLink) => {
    window.open(fileLink, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      <h2>Uploaded Posters</h2>
      {message && <p>{message}</p>}
      <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
        {posters.map((poster) => (
          <div key={poster._id} style={{ width: "200px", textAlign: "center" }}>
            <p>{poster.filename}</p>

            <a href={poster.fileLink} target="_blank" rel="noopener noreferrer">
              <Button type="primary" style={{ marginBottom: "8px" }}>
                Preview
              </Button>
            </a>

            {/* <a
              href={poster.downloadLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="primary" style={{ marginBottom: "8px" }}>
                Download
              </Button>
            </a> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewImages;
