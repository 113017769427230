import React from "react";
import YoutubeEmbed from "../../ui/embedded-video/YoutubeEmbed";
import Button from "../../ui/button/Button";

const ProjectWeek = () => {
  const scroll = () => {
    const section = document.querySelector("#why-attend");
    section.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <div className="container" style={{ padding: "30px 0px" }}>
      <div className="left-section">
        {/* <p
          style={{
            fontWeight: 500,
            fontSize: "12px",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            background: "linear-gradient(to right, #F2A408, #DE6811",
            display: "inline-block"
          }}
        >
          Get ready for the closing event on 24th April 2024
        </p> */}
        <h1 className="top-heading">1.000+ PROJECT WEEK 2025</h1>
        <div style={{ padding: "10px 0px" }}>
          <p style={{ fontWeight: 500, fontSize: "18px", color: "#DB6C1F" }}>
            Use the challenge-based learning with Hidden Champions to prepare
            your professional career.
          </p>
          <p style={{ fontWeight: 500, fontSize: "18px", color: "#64A0C8" }}>
            Be part of the project week to have access to TUM Talents and
            promote your business culture.{" "}
          </p>
          {/* <Button
            text="SIGN UP for Project Week 2025 is over - stay tuned..."
            style={{ fontWeight: 600, fontSize: "18px" }}
            variant="primary"
            onClick={scroll}
          /> */}
          <Button
            text="SIGN UP for 1.000+"
            style={{ fontWeight: 600, fontSize: "18px" }}
            variant="primary"
            onClick={scroll}
          />
          <p
            style={{
              fontWeight: 400,
              fontSize: "17px",
              color: "#515151",
              paddingTop: "10px",
              margin: 0,
            }}
          >
            <strong>Don't miss the chance to sign up</strong> (student
            registration ends by end of October 2024).
          </p>
        </div>
      </div>
      <div className="right-section">
        <YoutubeEmbed embedId="-cKiyxd09as" />
      </div>
    </div>
  );
};

export default ProjectWeek;
