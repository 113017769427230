import React from "react";

function TimelineItem({ data }) {
  return (
    <div className="timeline-item">
      <div className="timeline-item-content">
        <p style={{ fontWeight: 600, fontSize: "18px" }}>{data.date}</p>
        <p style={{ fontWeight: 300, fontSize: "14px" }}>{data.text}</p>
        {data.secondaryText != "" ? (
          <p style={{ fontWeight: 300, fontSize: "14px" }}>
            {data.secondaryText}
          </p>
        ) : null}

        <span className="circle" />
      </div>
    </div>
  );
}

export default TimelineItem;
