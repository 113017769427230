import React from "react";
import YoutubeEmbed from "../../ui/embedded-video/YoutubeEmbed";
import PhotoCarousel from "../../ui/photo-carousel/PhotoCarousel";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Carousel from "react-bootstrap/Carousel";

const CardComponent = ({ card }) => {
  const cardStyle = {
    borderTop: `5px solid ${card.borderColor}`, // Thicker border
    borderBottom: `5px solid ${card.borderColor}`, // Thicker border
    borderRight: `15px solid ${card.borderColor}`, // Thicker border
    borderLeft: `15px solid ${card.borderColor}`, // Thicker border
    borderRadius: "8px",
    padding: "20px",
    margin: "10px", // Add margin to avoid overlapping borders
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "350px", // Adjusted height to contain the largest card
    overflow: "hidden", // Hide overflow
  };

  const profileImageStyle = {
    borderRadius: "50%",
    width: "70px", // Increased width
    height: "70px", // Increased height
    marginRight: "15px",
  };

  const contentStyle = {
    flex: "1",
    overflowY: "auto", // Scrollable content
    scrollbarWidth: "thin", // Make the scrollbar smaller (for Firefox)
  };

  const textContainerStyle = {
    marginBottom: "10px", // Margin below the image and text
    overflowY: "auto", // Scrollable content
    scrollbarWidth: "thin", // Make the scrollbar smaller (for Firefox)
  };

  const scrollBarStyle = {
    "&::-webkit-scrollbar": {
      width: "6px", // Make the scrollbar smaller (for WebKit browsers)
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888", // Customize scrollbar thumb color
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555", // Customize scrollbar thumb hover color
    },
  };

  return (
    <div style={{ ...cardStyle, ...scrollBarStyle }}>
      <div
        style={{ ...textContainerStyle, display: "flex", alignItems: "center" }}
      >
        <img src={card.image} alt="Profile" style={profileImageStyle} />
        <div>
          <h5>{card.title}</h5>
          <h6 className="text-muted">{card.subtitle}</h6>
        </div>
      </div>
      <div style={{ ...contentStyle, ...scrollBarStyle }}>
        <p>{card.content}</p>
      </div>
    </div>
  );
};

const groupCards = (cards) => {
  const groups = [];
  for (let i = 0; i < cards.length; i += 2) {
    groups.push(cards.slice(i, i + 2));
  }
  return groups;
};

function Insights() {
  const headingStyle = {
    textAlign: "center",
    fontWeight: 600,
    padding: "40px",
    fontSize: "30px",
    color: "#313131",
  };

  const headingStyle2 = {
    textAlign: "center",
    fontWeight: 600,
    padding: "40px",
    fontSize: "24px",
    color: "#313131",
  };

  const carouselItemStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const carouselStyle = {
    paddingBottom: "40px", // Added padding to move carousel indicators down
    backgroundColor: "#FAFAFA", // Background color for the carousel
  };

  const weekTitleStyle = {
    textAlign: "center",
    fontWeight: 600,
    padding: "10px",
    fontSize: "28px", // Increased font size
    color: "#313131",
  };

  const insightData = {
    video: {
      embedId1: "wYBBiNVM7yg",
      embedId2: "q8FTne8mRMc",
    },
    images: [
      { src: "/insights/BBM_2.jpg", alt: "BBM" },
      { src: "/insights/Vitascale.jpg", alt: "Vitascale" },
      { src: "/insights/Presentation.jpg", alt: "Presentation" },
      { src: "/insights/IMG_2312.JPG", alt: "AirBus" },
      { src: "/insights/Foqee.jpg", alt: "Foqee" },
      { src: "/insights/Sciospec.jpg", alt: "Sciospec" },
      { src: "/insights/Peri_Students.jpeg", alt: "PERI" },
      { src: "/insights/InnoRoute.jpg", alt: "InnoRoute" },
      { src: "/insights/BBM.jpg", alt: "BBM" },
      { src: "/insights/20240424_124640.jpg", alt: "1.000+" },
      { src: "/insights/20240424_183735.jpg", alt: "1.000+" },
      { src: "/insights/Wurth.JPG", alt: "1.000+" },
      { src: "/insights/Blanc_Bild Studenten.jpeg", alt: "1.000+" },
      { src: "/insights/Guests_retouch.jpg", alt: "1.000+" },
      { src: "/insights/IMG_0155.JPG", alt: "1.000+" },
      { src: "/insights/Panel Round_retouch.jpg", alt: "1.000+" },
      { src: "/insights/PERI_retouch.jpg", alt: "1.000+" },
      { src: "/insights/BayernInnovativ_retouch.jpg", alt: "1.000+" },
    ],
    cards: [
      {
        title: "Julien A. - Master Student",
        subtitle: "TUM School of Management",
        content:
          "Have you ever dreamed of living an action movie? Just apply to 1.000+ Project Week... And boom one day you realize you are the chosen one! Very quickly things accelerate, you are sent the name of a German SME, the landing day, you discover avidly you are not alone but part of a singular student team, and, most importantly: you are assigned…THE MISSION. New places, new people, new industry, new challenges, and above all new solutions developed by your team. In only 5 days, you live an unmatched boost of professional experience. Just go for it!",
        image: "/insights/default_profile_picture.jpg", // Provide the path to the profile image
        borderColor: "#DB6C1F", // Example color for differentiation
        company: "Blanc & Fischer",
      },
      {
        title: "Aleksandar Jovicic - Employer Brand Specialist",
        subtitle: "BLANC & FISCHER Corporate Services",
        content:
          "Taking part in the 1.000+ project at the Technical University of Munich was an extraordinary experience for us. In just five days, we saw how international students developed innovative solutions for real entrepreneurial challenges. Working with these talented young people brought fresh perspectives and creative approaches to our company. The dynamism and enthusiasm that the students brought to the project was particularly impressive. This week definitely exceeded our expectations and we look forward to participating again next time.",
        image: "/insights/Jovicic_Bild.jpg", // Provide the path to the profile image
        borderColor: "#64A0C8", // Example color for differentiation
        company: "Blanc & Fischer",
      },
      {
        title: "Yash Thirani - Master Student",
        subtitle: "TUM School of Computation, Information and Technology",
        content:
          "I had a positive experience collaborating with 2 fellow students from Informatics on working in the rapidly emerging field of RAGs and LLMs. The company was clear about the expectations for the project from the very beginning and was helpful with research/feedback over the course of the week. The networking event with all participating companies afterward also provided insights into projects being undertaken at other companies and a chance to share our own work through posters. Being able to use the project's experience to get an official grade on the TUM transcript was a plus. Getting to experience the flexibility and speed of work at a startup was invaluable. It also provided a networking opportunity with the company which I was able to utilize for my ongoing Master's Thesis and working student role.",
        image: "/insights/YashFormal.jpeg", // Provide the path to the profile image
        borderColor: "#DB6C1F", // Example color for differentiation
        company: "Bluesolve GmbH",
      },
      {
        title: "Dr. Michael Mihatsch - Business Development",
        subtitle: "Bluesolve GmbH",
        content:
          "1.000+ was an overwhelmingly positive experience. The students exceeded our expectations and demonstrated great teamwork. A complete success! We were positively surprised by the independence and structured approach of the students, as well as the remarkable progress they made in a short amount of time. Their ability to work efficiently and achieve results quickly was truly impressive. For us, 1000+ offers a chance to engage with motivated and highly educated talents from one of the best universities. It also offers us the opportunity to gain fresh perspectives on challenges that we believe were already resolved in the best possible way.",
        image: "/insights/MichaelMihatsch.jpg", // Provide the path to the profile image
        borderColor: "#64A0C8", // Same border color for differentiation
        company: "Bluesolve GmbH",
      },
    ],
  };

  const groupedCards = groupCards(insightData.cards);

  return (
    <>
      <style>
        {`
    .carousel-indicators li {
      background-color: #888; /* Custom color for inactive indicators */
      width: 12px; /* Custom size for indicators */
      height: 12px; /* Custom size for indicators */
      border-radius: 50%; /* Make indicators round */
      margin-bottom: 20px; /* Move indicators down a bit */
    }

    .carousel-indicators .active {
      background-color: #000; /* Custom color for active indicator */
    }

    .carousel-control-prev, .carousel-control-next {
      width: 5%;
      color: black !important; /* Ensure the arrows are black */
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-image: none; /* Remove the default background image */
      border: solid black; /* Use border to create the arrow */
      border-width: 0 3px 3px 0; /* Adjust the border to create the arrow shape */
      display: inline-block;
      padding: 3px; /* Adjust padding to make arrows smaller */
      width: 15px; /* Adjust width */
      height: 15px; /* Adjust height */
    }

    .carousel-control-prev-icon {
      transform: rotate(135deg); /* Rotate to point left */
    }

    .carousel-control-next-icon {
      transform: rotate(-45deg); /* Rotate to point right */
    }

    .carousel-control-prev {
      left: -6%;
    }

    .carousel-control-next {
      right: -6%;
    }
  `}
      </style>

      <div id="insights" className="container">
        <div className="flex-fill">
          <h1 style={headingStyle}>Insights from 1.000+ Project Week 2024</h1>
          <div className="row d-flex justify-content-center ">
            <div className="col-12 col-lg-6 py-2">
              <YoutubeEmbed embedId={insightData.video.embedId1} />
            </div>
            <div className="col-12 col-lg-6 py-2 mb-4">
              <YoutubeEmbed embedId={insightData.video.embedId2} />
            </div>
          </div>
          <div className="col-12 py-2 mb-4">
            <Carousel
              style={carouselStyle}
              interval={10000}
              controls={true}
              indicators={true}
            >
              {" "}
              {/* Auto-switch interval set to 5 seconds */}
              {groupedCards.map((cardGroup, index) => (
                <Carousel.Item key={index}>
                  <div style={carouselItemStyle}>
                    <h1 style={headingStyle2}>
                      Project Week at {cardGroup[0].company}
                    </h1>
                    <div className="d-flex justify-content-center flex-wrap">
                      {cardGroup.map((card, idx) => (
                        <div className="col-12 col-md-6 d-flex justify-content-center">
                          <CardComponent key={idx} card={card} />
                        </div>
                      ))}
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div className="custom-container1" style={{ paddingBottom: "30px" }}>
            <PhotoCarousel images={insightData.images} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Insights;
