import React, { useState } from "react";
import Poster from "./Poster";
import PosterDetail from "./PosterDetail";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

const PostersPage = () => {
  const [selectedPoster, setSelectedPoster] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const posters = [
    {
      id: 1,
      imageThumbnailSrc: "./Thumbnail_Team_Foqee.jpg",
      imageSrc: "./Team_Foqee.jpg",
      title: "Team Foqee GmbH",
      description: "Description for Team Foqee poster.",
      companyDetails: "Details about Team Foqee.",
    },
    {
      id: 2,
      imageThumbnailSrc: "./Thumbnail_Team_Wurth.jpg",
      imageSrc: "./Team_Wurth.jpg",
      title: "Team Würth Elektronik ICS GmbH & Co. KG",
      description: "Description for Team Wurth poster.",
      companyDetails: "Details about Team Wurth.",
    },
    {
      id: 3,
      imageThumbnailSrc: "./Thumbnail_Team I_MÃller.jpg",
      imageSrc: "./Team I_MÃller.jpg",
      title: "Team Müller-BBM Industry Solutions GmbH",
      description: "Description for Team Müller poster.",
      companyDetails: "Details about Team Müller.",
    },

    // Add other posters similarly...
  ];

  const handlePosterClick = (poster) => {
    setSelectedPoster(poster);
  };

  const handleBackClick = () => {
    setSelectedPoster(null);
  };

  const posterStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fafafa",
  };

  const headerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "30px",
    marginBottom: "30px",
  };

  return (
    <div style={posterStyle}>
      {selectedPoster ? (
        <PosterDetail
          imageSrc={selectedPoster.imageSrc}
          title={selectedPoster.title}
          description={selectedPoster.description}
          companyDetails={selectedPoster.companyDetails}
          onNavigate={handleBackClick}
        />
      ) : (
        <div>
          <h1 style={headerStyle}>Poster Results</h1>
          <Swiper
            spaceBetween={1}
            autoplay={{ delay: 3000 }}
            slidesPerView={3} // Default to 1 slide per view for stacking
            breakpoints={{
              // When the window width is >= 768px, display 2 slides per view
              768: {
                slidesPerView: 2,
              },
              // When the window width is >= 1024px, display 3 slides per view
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {console.log("windowWidth", windowWidth)}
            {posters.map((poster) =>
              windowWidth < 820 ? (
                <div>
                  <SwiperSlide key={poster.id}>
                    <Poster
                      imageThumbnailSrc={poster.imageThumbnailSrc}
                      altText={poster.title}
                      onClick={() => handlePosterClick(poster)}
                    />
                  </SwiperSlide>
                </div>
              ) : (
                <SwiperSlide key={poster.id}>
                  <Poster
                    imageThumbnailSrc={poster.imageThumbnailSrc}
                    altText={poster.title}
                    onClick={() => handlePosterClick(poster)}
                  />
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default PostersPage;
