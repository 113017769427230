import React, { useState, useEffect } from "react";
import { Carousel } from "antd";

const quoteStyle = {
  fontSize: "16px",
  margin: "20px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "100%",
};

const logoStyle = {
  width: "auto",
  height: "120px",
  display: "block",
  margin: "20px auto",
};

const authorStyle = {
  paddingLeft: "20px",
  fontWeight: 500,
  fontSize: "15px",
};

const companyStyle = {
  fontWeight: 400,
};

const cardStyle = {
  backgroundColor: "white",
  borderRadius: "6px",
  padding: "20px",
  margin: "20px 10px",
  display: "flex",
  flexDirection: "column",
  height: "350px", // Set a fixed height for the card
};

const testimonialData = [
  {
    text: "We see 1.000+ Project Week as a great opportunity to have students with different yet suitable backgrounds develop solutions to real problems and also to present ourselves to talented people as potential employer.",
    author: "Sebastian Kuhn, Consultant Corporate Strategy & Projects",
    company: "PERI SE",
    mainLogo: "./companyLogos/PERI1.png",
    subLogo: "",
  },
  {
    text: "TUM's 1000+ initiative has brought us together with a fantastic group of students. A fresh perspective, lots of energy and motivation have immediately rubbed off on our team. Consequently, it was not only the students taking home a lesson. A truly fun way of doing student projects!",
    author: "Sebastian Wegner - CEO",
    company: "Sciospec",
    mainLogo: "./companyLogos/ScioSpec.png",
    subLogo: "",
  },
  {
    text: "Young talents from the fields of computer science, mathematics and aviation got to know us as an innovative employer and now want to write their thesis with us. Many thanks to TUM for this opportunity! We look forward to further encounters, because at Airbus, talented young people always have the opportunity to help shape innovative future projects, especially in the field of computer science.",
    author: "Marina Reich - TOR & D&C Architecture",
    company: "Airbus Defence and Space",
    mainLogo: "./companyLogos/AirBus1.png",
    subLogo: "",
  },
  {
    text: "New ideas need new talents. Talents need challenges. That's why the 1000+ Project Challenge is such a good idea: SMEs and students work together, learn from each other - and have a good time!",
    author: "Jan Philipps - Managing Director",
    company: "Foqee",
    mainLogo: "./companyLogos/FoQee1.png",
    subLogo: "",
  },
  {
    text: "The success of companies depends on their ability to discover talents, inspire them, and provide an ecosystem where they can fully unleash their innovative power. The TUM project week bridges the gap between theory and practice, benefiting not only the students but also the participating companies and Germany as a whole.",
    author: "Hanbing Ma - Head of Innovation & Digital Transformation",
    company: "ERGO Innovation Lab in Berlin",
    mainLogo: "./Ergo_logo.png",
    subLogo: "",
  },
  {
    text: "Theory meets practice - TUM CIT lays the foundations and connects them with practice to secure young talents for SMEs that are important for their future and for Bavaria as an innovation hub in Health Tech!",
    author: "Dr. Joerg Traub - Leitung Gesundheit",
    company: "Bayern Innovativ GmbH",
    mainLogo: "./companyLogos/DigiCareLogo.jpg",
    subLogo: "./BayernInnovative_logo.png",
  },
  {
    text: "I worked for two years in the field of economic policy. During that time I realized how important industrial culture is. I hope that this project week can provide me new input to understand which drivers are behind successful KMU´s.",
    author: "Susan W. - Master's Student",
    company: "TUM Munich",
    mainLogo: "./TUM_blue_logo.png",
    subLogo: "",
  },
  {
    text: "Entrepreneurship requires talent - new individuals with ideas. Our people, our talents, are our greatest strength. We, in medium-sized businesses, in SMEs, are therefore enthusiastic about the initiative of TUM.",
    author: "Dr. Philipp Baaske - CEO",
    company: "NanoTemper Technologies GmbH",
    mainLogo: "./NanoTemper_logo.png",
    subLogo: "",
  },
  {
    text: "Diving into the Projektwoche bridges academia and SME realities. It's a golden chance to understand where my academic skills meet real-world demand. Excited to uncover the intricacies of these hidden champions!",
    author: "Daniel L. - Master's Student",
    company: "TUM Munich",
    mainLogo: "./TUM_blue_logo.png",
    subLogo: "",
  },
];

const AntCarousel = () => {
  const [perPage, setPerPage] = useState(calculatePerPage());

  // Function to calculate the number of slides per page based on screen size
  function calculatePerPage() {
    return window.innerWidth < 991 ? 1 : 2;
  }

  // Event listener to update perPage state on window resize
  useEffect(() => {
    function handleResize() {
      setPerPage(calculatePerPage());
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to ensure effect runs only once

  return (
    <Carousel
      autoplay
      autoplaySpeed={5000}
      slidesToShow={perPage}
      dots={true}
      swipeToSlide
      draggable
    >
      {testimonialData.map((testimonial, index) => (
        <div key={index}>
          <div style={cardStyle}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={testimonial.mainLogo}
                alt="Company Logo"
                style={{
                  height: "60px",
                  width: "60px",
                  borderRadius: "50%",
                  objectFit: "scale-down",
                }}
              />
              <div style={authorStyle}>
                {testimonial.author} at {testimonial.company}
              </div>
              {testimonial.subLogo !== "" ? (
                <img
                  src={testimonial.subLogo}
                  alt="Company Logo"
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                    objectFit: "scale-down",
                  }}
                />
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            ></div>
            <div style={quoteStyle}>"{testimonial.text}"</div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default AntCarousel;
